import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { SendEnquiryBlock } from '/src/components/common'

import './safety_data_sheets.scss'

export const Head = ({ data }) => {
  return <title>Safety Data Sheets | Metalcorp</title>
}

const CatalogueListItem = ({ title, path, relationships }) => {
  const { field_resource_cover } = relationships

  return (
    <Link to={path.alias} className="catalogue-list-item">
      <div className="image-container">
        {field_resource_cover && (
          <GatsbyImage alt={title} image={field_resource_cover.gatsbyImage} />
        )}
      </div>
      <div className="title-container d-flex align-items-center justify-content-center">
        <h4 className="title">{title}</h4>
      </div>
    </Link>
  )
}

const CatalogueList = ({ items }) => {
  return (
    <div className="catalogue-list">
      <Row>
        {items.map((item, index) => {
          return (
            <Col key={index} className="mb-4" sm={4}>
              <CatalogueListItem {...item.node} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default ({ data }) => {
  const resources = data.allNodeResources.edges

  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <div className="mb-5">
            <Link className="back-to-link" to='/resources'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to resources
            </Link>
          </div>
          <CatalogueList items={resources} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query {
    allNodeResources(
      filter: {relationships: {field_resource_category: {elemMatch: {name: {eq: "Safety Data Sheets"}}}}}
      sort: {fields: created, order: DESC}
    ) {
      edges {
        node {
          body {
            processed
          }
          path {
            alias
          }
          title
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_cover {
              gatsbyImage(
                fit: COVER
                formats: AUTO
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                width: 315
                height: 225
              )
            }
            field_resource_category {
              name
            }
          }
        }
      }
    }
  }
`

/*export const query = graphql`
  query {
    allNodeResources(
      filter: {relationships: {field_resource_category: {elemMatch: {name: {eq: "Safety Data Sheets"}}}}}
      sort: {fields: created, order: DESC}
    ) {
      edges {
        node {
          body {
            processed
          }
          path {
            alias
          }
          title
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_file {
              publicUrl
            }
            field_resource_cover {
              gatsbyImage(
                fit: COVER
                formats: AUTO
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                width: 315
                height: 225
              )
            }
            field_resource_category {
              name
            }
          }
        }
      }
    }
  }
`*/
